import { SocialLink } from '../typings';
import Github from "../assets/images/socials/github.svg";
import Youtube from "../assets/images/socials/youtube.svg";
import Telegram from "../assets/images/socials/telegram.svg";
import Twitter from "../assets/images/socials/twitter.svg";

export const socials: Array<SocialLink> = [
    {
        name: "Twitter",
        image: Twitter,
        url: "#",
    },
    {
        name: "Telegram",
        image: Telegram,
        url: "#",
    },
    {
        name: "Youtube",
        image: Youtube,
        url: "#",
    },
    {
        name: "Github",
        image: Github,
        url: "#",
    },
];