import { Banner } from '../typings';
import Banner1 from '../assets/images/banners/banner_1.png';
import Banner2 from '../assets/images/banners/banner_2.png';
import Banner3 from '../assets/images/banners/banner_3.png';

export const banners: Array<Banner> = [
    {
        image: Banner1,
        url: "#",
    },
    {
        image: Banner2,
        url: "#",
    },
    {
        image: Banner3,
        url: "#",
    },
];
