import { Feature } from '../typings';

export const features: Array<Feature> = [
    {
        href: "#",
        iconName: "support",
        title: "app.features.support.title",
        desc: "app.features.support.desc",
    },
    {
        href: "#",
        iconName: "blog",
        title: "app.features.blog.title",
        desc: "app.features.blog.desc",
    },
    {
        href: "#",
        iconName: "community",
        title: "app.features.community.title",
        desc: "app.features.community.desc",
    },
    {
        href: "#",
        iconName: "blog",
        title: "app.features.careers.title",
        desc: "app.features.careers.desc",
    },
];